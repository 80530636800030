import React from 'react';
import { Box, Grid, Show } from '@core';
import { Flag, Text } from '@components';

const CountryList = ({ data }) => (
  <Grid.Container overflowX="auto" px={24}>
    <Grid.Row flexDirection={{ _: 'column', lg: 'row' }} maxHeight={{ _: 469, lg: 'unset' }}>
      {data.map(({ isoAlpha2, isoAlpha3, name }) => (
        <Grid.Col
          width={{ _: 108, sm: 172, lg: '20%' }}
          display="flex"
          alignItems="center"
          mb={32}
          key={isoAlpha2}
        >
          <Box display="flex" flexShrink={0}>
            <Flag iso={isoAlpha2} width={33} />
          </Box>
          <Show.TabletLarge>
            <Text.Body3Strong mx={16}>{isoAlpha3}</Text.Body3Strong>
          </Show.TabletLarge>
          <Show.DesktopAndUp>
            <Text.Body3Strong mx={16}>{name}</Text.Body3Strong>
          </Show.DesktopAndUp>
        </Grid.Col>
      ))}
    </Grid.Row>
  </Grid.Container>
);

export default CountryList;
