import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@core';
import { LinkWrapper, SectionContainerSC as SectionContainer } from '@components';
import { ImageCore } from '@components/image';
import { query } from '@shared/style-helpers';
import { useServersQuery } from '@data/servers';
import { filterByUniqueKey, sortDataByKey } from '@shared/helpers';
import { useI18nCountries } from '@hooks';

import worldMap from '@images/country-list/world-map.png';

import { CountryList } from './components';

const sortTranslatedCountries = ({ serverCountries, language, i18nCountries }) => {
  const countryData = serverCountries.reduce((data, { iso_code: iso }) => {
    data.push({
      isoAlpha2: iso,
      isoAlpha3: i18nCountries.alpha2ToAlpha3(iso),
      name: i18nCountries.getName(iso, language, { select: 'alias' }),
    });

    return data;
  }, []);

  return countryData.length ? sortDataByKey(countryData, 'name') : null;
};

const getCountryData = ({ locations, language, i18nCountries }) => {
  if (!locations) {
    return null;
  }
  const filteredLocations = filterByUniqueKey(locations, 'iso_code');
  const translatedCountries = sortTranslatedCountries({
    serverCountries: filteredLocations,
    language,
    i18nCountries,
  });

  // remapping political country names (Province of China - Taiwan to Taiwan Region)
  const remappedCountryNames = translatedCountries.map((country) =>
    country.isoAlpha2 === 'TW' && language === 'zh' ? { ...country, name: '台湾' } : country
  );

  return remappedCountryNames;
};

const AbsoluteImage = styled(ImageCore)`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: unset;
  height: 100%;
  @media ${query.maxMobile} {
    height: 60%;
    top: 10%;
  }
`;

export const CountryListSectionWithoutDataFetch = ({
  serverData,
  title,
  description,
  button,
  image,
}) => {
  const {
    i18n: { language },
  } = useTranslation();

  const { i18nCountries } = useI18nCountries(language);

  const [countryData, setCountryData] = useState(null);

  useEffect(() => {
    if (!countryData && serverData && i18nCountries) {
      setCountryData(
        getCountryData({
          locations: serverData?.locations,
          language: language.split('-')[0],
          i18nCountries,
        })
      );
    }
  }, [countryData, language, serverData, i18nCountries]);

  return (
    <SectionContainer
      data-section-id="CountryList"
      title={title}
      description={description}
      containerProps={{ overflowX: 'hidden' }}
    >
      <Box position="relative">
        {countryData && <CountryList data={countryData} />}

        {button && (
          <Box display="flex" justifyContent="center" mt={{ md: 24 }}>
            <Box minWidth={280}>
              <LinkWrapper link={button.link} withParams>
                <Button type="button" size="large" fullWidth {...button.buttonProps}>
                  {button.title}
                </Button>
              </LinkWrapper>
            </Box>
          </Box>
        )}

        {image && <AbsoluteImage src={image} alt="world map" />}
      </Box>
    </SectionContainer>
  );
};

const CountryListSection = ({ title, description, button, image = worldMap }) => {
  const serverData = useServersQuery();

  return (
    <CountryListSectionWithoutDataFetch
      serverData={serverData}
      title={title}
      description={description}
      button={button}
      image={image}
    />
  );
};

export default CountryListSection;
